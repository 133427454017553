import Vue from 'vue'
import VueRouter from 'vue-router'
import mainLayout from "@/components/mainLayout"
import home from "./modules/home"
import jobmanage from "./modules/jobmanage"
import application from "./modules/application"
import messageManage from "./modules/messageManage"
import salary from "./modules/salary"
import aBonus from "./modules/aBonus"
import withdrawal from "./modules/withdrawal"
import systemsetup from "./modules/systemsetup"

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'hash',
    routes: [
        {
        name: '登录',
        path: '/',
            component: () => import("@/pages/login/login.vue")
        },
        {
            path: '/admin',
            meta: { title: '兼职管理系统' },
            component: mainLayout,
            children: [
                ...home,
                jobmanage,
                application,
                messageManage,
                salary,
                aBonus,
                withdrawal,
                systemsetup
            ]
        },
    ]
})

router.beforeEach((to, from, next) => {
  window.document.title = "兼职管理系统";
  next();
})
export default router
