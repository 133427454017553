import request from '@/utils/request'
//登录
export function login (data) {
    return request({
        url: '/login/login',
        method: 'post',
        data
    })
}
//退出
export function loginLogout (data) {
    return request({
        url: '/login/logout',
        method: 'post',
        data
    })
}
// 修改密码
export function loginModify (data) {
    return request({
        url: '/login/modify',
        method: 'post',
        data
    })
}

//职位列表
export function jobsList (data) {
    return request({
        url: '/jobs/list',
        method: 'get',
        params: data
    })
}
// 职位上下架
export function jobs_modify_status (data) {
    return request({
        url: '/jobs/modify_status',
        method: 'post',
        data
    })
}
// 删除职位
export function jobsDel (data) {
    return request({
        url: '/jobs/del',
        method: 'post',
        data
    })
}
// 创建修改职位
export function jobsCreate (data) {
    return request({
        url: '/jobs/create',
        method: 'post',
        data
    })
}
//岗位下拉列表
export function jobs_posts_list (data) {
    return request({
        url: '/jobs/posts_list',
        method: 'get',
        params: data
    })
}
//结算方式下拉列表
export function jobs_settle_list (data) {
    return request({
        url: '/jobs/settle_list',
        method: 'get',
        params: data
    })
}
//职位类目下拉列表
export function jobs_category_list (data) {
    return request({
        url: '/jobs/category_list',
        method: 'get',
        params: data
    })
}
// 删除图片
export function other_del_img (data) {
    return request({
        url: '/other/del_img',
        method: 'post',
        data
    })
}

//报名列表
export function signupList (data) {
    return request({
        url: '/signup/list',
        method: 'get',
        params: data
    })
}
// 修改状态
export function signup_modify_status (data) {
    return request({
        url: '/signup/modify_status',
        method: 'post',
        data
    })
}
//放鸽子记录
export function signup_fail_list (data) {
    return request({
        url: '/signup/fail_list',
        method: 'get',
        params: data
    })
}
//报名导出
export function signupExport (data) {
    return request({
        url: '/signup/export',
        method: 'get',
        params: data
    })
}

//留言记录列表
export function messageList (data) {
    return request({
        url: '/message/list',
        method: 'get',
        params: data
    })
}
//个人留言记录
export function message_user_list (data) {
    return request({
        url: '/message/user_list',
        method: 'get',
        params: data
    })
}
// 回复留言
export function messageReply (data) {
    return request({
        url: '/message/reply',
        method: 'post',
        data
    })
}
// 创建常用语
export function message_create_phrases (data) {
    return request({
        url: '/message/create_phrases',
        method: 'post',
        data
    })
}
//常用语列表
export function message_phrases_list (data) {
    return request({
        url: '/message/phrases_list',
        method: 'get',
        params: data
    })
}
// 删除常用语
export function message_del_phrases (data) {
    return request({
        url: '/message/del_phrases',
        method: 'post',
        data
    })
}
// 全部已读
export function message_all_message_use (data) {
    return request({
        url: '/message/all_message_use',
        method: 'post',
        data
    })
}

//余额分红列表
export function balanceList (data) {
    return request({
        url: '/balance/list',
        method: 'get',
        params: data
    })
}

//提现列表
export function balance_withdraw_list (data) {
    return request({
        url: '/balance/withdraw_list',
        method: 'get',
        params: data
    })
}
// 提现
export function balanceWithdraw (data) {
    return request({
        url: '/balance/withdraw',
        method: 'post',
        data
    })
}

// 导出
export function wagesExport (data) {
    return request({
        url: '/wages/export',
        method: 'post',
        data
    })
}
// 导入列表
export function wages_import_list (data) {
    return request({
        url: '/wages/import_list',
        method: 'post',
        data
    })
}
//导入的文件详情
export function wages_import_infos (data) {
    return request({
        url: '/wages/import_infos',
        method: 'get',
        params: data
    })
}
// 删除导入的文件
export function wages_del_export_file (data) {
    return request({
        url: '/wages/del_export_file',
        method: 'post',
        data
    })
}
// 商家审核工资
export function wages_examine_file (data) {
    return request({
        url: '/wages/examine_file',
        method: 'post',
        data
    })
}

//获取用户信息
// jobs.puhuijishu.top/pc/user_info
export function user_info (data) {
    return request({
        url: '/user_info',
        method: 'get',
        params: data
    })
}
// 修改用户信息
// jobs.puhuijishu.top/pc/update_info
export function update_info (data) {
    return request({
        url: '/update_info',
        method: 'post',
        data
    })
}