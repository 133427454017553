/** 系统设置路由 */
export default {
    name: 'systemsetup',
    path: 'systemsetup',
    meta: {
        title: '系统设置'
    },
    component: ()=>import('@/pages/systemsetup/systemsetup.vue'),
    children: [
        {
            name: 'submenu-aboutus',
            path: '/systemsetup/changepassword',            
            meta: {
                title: '修改密码'
            },
            component: ()=>import('@/pages/systemsetup/submenu/changepassword.vue')
        },
        {
            name: 'submenu-aboutus',
            path: '/systemsetup/business',            
            meta: {
                title: '商家信息'
            },
            component: ()=>import('@/pages/systemsetup/submenu/business.vue')
        },
    ]
}